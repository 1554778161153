import "./style.css";
import InstallerFileDownload from "components/InstallerFileDownload";

const Download = () => {
  return (
    <>
      <a href="https://productivemachines.co.uk/">
        <img
          className="login-logo m-2 ml-4"
          src="assets/images/ll4.png"
          alt="logo"
        />
      </a>
      <div className="account-pages m-0" style={{ backgroundColor: "#134a9a" }}>
        <div style={{ padding: "200px" }}>
          <div
            style={{
              marginBottom: "50px",
            }}
          >
            <div className="row border-class">
              <div className="col d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center flex-column">
                  <div className="p-1">
                    <h1
                      className="text-center"
                      style={{
                        color: "white",
                        fontSize: "36px",
                        fontFamily: "Poppins",
                      }}
                    >
                      SenseNC Feeds
                    </h1>
                    <h1
                      className="text-center mt-4"
                      style={{
                        color: "white",
                        fontSize: "36px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Try if for FREE
                    </h1>
                    <div
                      className="d-flex align-items-center justify-content-center flex-column"
                      style={{ maxWidth: "500px" }}
                    >
                      <h5
                        className="text-center mt-4"
                        style={{
                          color: "white",
                          fontSize: "21px",
                          fontFamily: "Poppins",
                        }}
                      >
                        Get optimal feed rates automatically for complex
                        towpaths within your NX CAM environment.
                      </h5>
                      <h5
                        className="text-center mt-4"
                        style={{
                          color: "white",
                          fontSize: "21px",
                          fontFamily: "Poppins",
                        }}
                      >
                        Easy-to-use, ultra-fast Siemens NX CAM plug-in optimizes
                        5-axis toolpaths with feed rates based on equalization
                        of forces and chip thickness.
                      </h5>
                    </div>
                  </div>
                  <InstallerFileDownload
                    buttonText=" Download Installer for SenseNC"
                    fileName="sensenc.zip"
                    style={{
                      color: "white",
                      fontSize: "18px",
                      fontFamily: "Poppins",
                    }}
                  />
                </div>
              </div>
              <div className="col d-flex align-items-center justify-content-center">
                <div>
                  <img
                    className="login-image mr-5"
                    src="assets/images/ProductionMachinesLaptop.png"
                    alt="logo"
                    width="600px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Download;
