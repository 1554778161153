import http from "utils/http";
import { files } from "constants/endpoints";

const installerDownloadFile = ({ fileName }) => {
  const endpoint = files.installerDownloadFile();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${endpoint}`, {
    data: {
      fileName,
    },
    responseType: "blob",
  });
};

export default installerDownloadFile;
