import React, { useState } from "react";
import installerDownloadFile from "services/files/installerDownloadFile";
import saveFile from "utils/saveFile";
import notify from "utils/toastMsg";

const InstallerFileDownload = ({ buttonText, fileName, ...rest }) => {
  const [loading, setLoading] = useState(false);
  if (!fileName) {
    return null;
  }

  return (
    <>
      {Boolean(loading) ? (
        <button
          {...rest}
          className="btn btn-primary btn-lg login-page-download-button"
          disabled
        >
          Downloading...
        </button>
      ) : (
        <button
          {...rest}
          className="btn btn-primary btn-lg login-page-download-button"
          onClick={async () => {
            setLoading(true);
            const response = await installerDownloadFile({ fileName });
            const contentDisposition = response.headers["content-disposition"];
            const originalFileName = contentDisposition
              .split("=")[1]
              .split('"')[1];
            saveFile(originalFileName, response);
            setLoading(false);
            notify("Downloaded successfully. ✅ ");
          }}
        >
          {buttonText ? buttonText : `Download`}
        </button>
      )}
    </>
  );
};

export default InstallerFileDownload;
